<template>
  <div class="projects_list">
      <h3 class="instagram-headline">@secondbandshirt</h3>
      <div class="project" v-for="(project, index) in store.instagramGroups" :key="project.id" v-on:click="showLinkDetail(index)">
        
      <!-- header -->
      <div class="project__header_content">
        <h4>{{store.formatDate(project.postDate)}}</h4>
        <h2>{{project.headline}}</h2>
      </div>
      <a class="next-link next-info">mehr infos</a>
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'Instagram',
  metaInfo: {
    title: 'Second Bandshirt - Instagram',
    htmlAttrs: {
      lang: 'de',
      amp: true
    }
  },
  components: {
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    showLinkDetail(index){
      window.open(this.store.instagramGroups[index].linkUrl, "_blank");
    },
  }
}
</script>

<style lang="scss">
  .next-info{
    padding-bottom: 0!important;
  }
  .instagram-headline{
    text-align: center;
    color: $color-primary;
  }
</style>
